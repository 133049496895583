import { useCallback, useEffect, useRef, useState } from "react";
import Loader2 from "../../sections/utilities/Loader2";
import PageDescription from "../../layout/page-description";
import { debounce } from "lodash";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useNavigate } from "react-router";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import ButtonNeoGen from "../../layout/button-neogen";
import { useBreakpoint } from "../../hooks/appMedia";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import OptionsDropDown from "../../layout/options-dropdown";
import SearchField from "../../layout/search-field";
import { ViewportList } from "react-viewport-list";
import mandateService, { Mandate } from "../../services/mandate.service";
import { useQuery } from "@tanstack/react-query";

function MandatesPage({ darkMode }: { darkMode?: boolean }) {
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement | null>(null);
    const mandatesQuery = useQuery(["mandatesAll", debouncedSearch], async () => {
        const result = await mandateService.getMandates({
            ...(debouncedSearch.length > 0 ? { where: { name: { like: `%${debouncedSearch}%` } } } : {}),
        });
        return result ?? [];
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (term) => {
                setDebouncedSearch(term);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );

    useEffect(() => {
        updateDebouncedSearch(search);
    }, [search, updateDebouncedSearch]);

    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";

    return (
        <>
            <PageDescription title="Mandates">
                <SearchField search={search} setSearch={setSearch} placeholder="Search mandates..." />
            </PageDescription>
            <div ref={ref} style={{ height: "calc(100vh - 270px)", overflow: "auto" }}>
                {false ? (
                    <div className={"p-5 text-center"}>
                        <Loader2 />
                    </div>
                ) : !isMobile && !isTablet ? (
                    <>
                        <Table>
                            <THead sticky>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>City</Th>
                                    <Th>State</Th>
                                    <Th>County</Th>
                                    <Th />
                                </Tr>
                            </THead>
                            <TBody>
                                {mandatesQuery.isLoading && (
                                    <Tr>
                                        <Td colSpan={5} style={{ textAlign: "center" }}>
                                            No mandates
                                        </Td>
                                    </Tr>
                                )}
                                {(mandatesQuery.data?.pageItems || []).length > 0 && (
                                    <ViewportList
                                        viewportRef={ref}
                                        items={mandatesQuery.data?.pageItems}
                                        renderSpacer={({ ref, style }) => <Tr ref={ref} style={style} />}
                                        scrollThreshold={100}
                                        initialPrerender={40}
                                        overscan={10}
                                    >
                                        {(mandate) => {
                                            return (
                                                <Tr key={mandate.id}>
                                                    <Td>{mandate.name || "-"}</Td>
                                                    <Td>{mandate.city || "-"}</Td>
                                                    <Td>{mandate.state || "-"}</Td>
                                                    <Td>{mandate.county || "-"}</Td>
                                                    <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                        <ButtonNeoGen
                                                            size="sm"
                                                            onClick={() => {
                                                                navigate(`/mandates/${mandate.id}`);
                                                            }}
                                                        >
                                                            Open mandate
                                                        </ButtonNeoGen>
                                                    </Td>
                                                </Tr>
                                            );
                                        }}
                                    </ViewportList>
                                )}
                            </TBody>
                        </Table>
                    </>
                ) : (
                    <ul>
                        {(mandatesQuery.data?.pageItems || []).map((mandate) => (
                            <li
                                key={mandate.id}
                                className="bg-gray-50 dark:bg-gray-700 dark:border-gray-900 p-4 rounded-lg shadow-md mb-4"
                            >
                                <div className="flex items-start justify-between">
                                    <div>
                                        <div className="flex gap-2 pb-1">
                                            <BuildingOffice2Icon
                                                color={darkMode ? "#4ade80" : "#4338ca"}
                                                className="w-5 h-5"
                                            />
                                            <span>{mandate.name || "Unknown"}</span>
                                        </div>
                                        <div className="flex gap-2 pb-1">
                                            <BuildingOffice2Icon
                                                color={darkMode ? "#4ade80" : "#4338ca"}
                                                className="w-5 h-5"
                                            />
                                            <span>{mandate.city || "Unknown"}</span>
                                        </div>
                                        <div className="flex gap-2 pb-1">
                                            <BuildingOffice2Icon
                                                color={darkMode ? "#4ade80" : "#4338ca"}
                                                className="w-5 h-5"
                                            />
                                            <span>{mandate.state || "Unknown"}</span>
                                        </div>
                                    </div>
                                    <OptionsDropDown
                                        asEllipses
                                        options={[
                                            {
                                                label: "Open mandate",
                                                action: () => navigate(`/mandates/${mandate.id}`),
                                                icon: "fa fa-pencil",
                                            },
                                        ]}
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    );
}

export default MandatesPage;
