import { z } from "zod";

export const mandateMandateEffectSchema = z.object({
    id: z.number(),
    mandateId: z.number(),
    mandateEffectId: z.number(),
    description: z.string().nullish(),
    probability: z.number(),
});

export type MandateMandateEffect = z.infer<typeof mandateMandateEffectSchema>;
